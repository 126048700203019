<script>
	import '../app.css';
	import '@fontsource-variable/open-sans';
</script>

<svelte:head>
	<title>pndr</title>
	<meta name="description" content="Live events and music industry digital services since 2021" />
</svelte:head>
	
<slot />